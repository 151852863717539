<template>
    <div class="card-section">
        <div class="card-title">
            <span>Standuply</span>

            <el-dropdown trigger="click">
                <el-button class="dropdown-button">
                    {{ isCurrentWeek(selectedWeek) ? "Current week" : "Week " + selectedWeek.week_number }}
                    <i class="el-icon-caret-bottom"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu class="standuply-dropdown">
                        <el-dropdown-item v-for="week in standuply" v-bind:key="week.week_year + week.week_number" @click="changeWeek(week)" :class="isSelected(week)">
                            <div class="week">
                                <span class="submission_rate" :style="getSubmissionRateColor(week.submission_rate)">
                                    <span v-if="week.submission_rate != 0">{{ week.submission_rate }}%</span>
                                    <img v-else src="../assets/holiday.png" alt="">
                                </span>
                                Week {{ week.week_number }}
                                <span class="date"> &bull; {{ week.week_start }} - {{ week.week_end }} </span>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <el-popover
                    placement="right-start"
                    :width="236"
                    trigger="click"
                    v-model:visible="isPopoverVisible"
            >
                <template #reference>
                    <span class="icon info-button" @click="isPopoverVisible = true">
                        <!--TODO: Replace with pro version icon-->
                        <i class="fas fa-info"></i>
                    </span>
                </template>

                <div class="popover legend">
                    <div class="top-section">
                        <div class="title">Standuply legend</div>
                        <div class="close icon" @click="isPopoverVisible = !isPopoverVisible">
                            <i class="el-icon-close"></i>
                        </div>
                    </div>
                    <div class="bottom-section">
                        <div>
                            <Circle leftColor="#8CBF7F" rightColor="#8CBF7F" :size="15" />
                            Both check-ins submitted
                        </div>
                        <div>
                            <Circle leftColor="#8CBF7F" rightColor="white" :size="15" />
                            One check-in submitted
                        </div>
                        <div>
                            <Circle leftColor="#AF6555" rightColor="#AF6555" :size="15" />
                            Both check-ins not submitted
                        </div>
                        <div>
                            <Circle leftColor="#AF6555" rightColor="white" :size="15" />
                            One check-in not submitted
                        </div>
                        <div>
                            <Circle leftColor="#E7E7E7" rightColor="#E7E7E7" :size="15" />
                            Future check-in
                        </div>
                        <div>
                            <Circle leftColor="#E0C183" rightColor="#E0C183" :size="15" />
                            Full-day vacation
                        </div>
                        <div>
                            <Circle  leftColor="#E0C183" rightColor="transparent" :size="15" />
                            Half-day vacation
                        </div>
                    </div>
                </div>
            </el-popover>
        </div>
        <i class="el-icon-caret-bottom" :style="getCurrentDay"></i>

        <div class="card-content" v-if="selectedWeek?.users?.length">
            <div v-for="item in selectedWeek.users" :key="item" class="standuply">
                <div>
                    <el-popover
                            placement="bottom"
                            :width="240"
                            trigger="hover"
                    >
                        <template #reference>
                            <el-avatar :src="item.user.avatar_url" class="avatar"></el-avatar>
                        </template>

                        <div class="tooltip">
                            <div class="top-section">
                                <div>
                                    <el-avatar :src="item.user.avatar_url" class="avatar"></el-avatar>
                                </div>
                                <div>
                                    <div class="name"> {{ item.user.name }} </div>
                                    <div class="job"> {{ item.user.job_title }} </div>
                                </div>
                            </div>
                            <div class="middle-section">
                                <div>
                                    <div class="icon">
                                        <!--TODO: Replace with pro version icon-->
                                        <a :href="`tel: ${item.user.phone}`"><i class="fas fa-phone"></i></a>
                                    </div>
                                    <div class="text">
                                        {{ item.user.phone }}
                                    </div>
                                </div>
                                <div>
                                    <div class="icon">
                                        <!--TODO: Replace with pro version icon-->
                                        <a :href="`mailto:${item.user.email}`"><i class="fas fa-envelope"></i></a>
                                    </div>
                                    <div class="text">
                                        {{ item.user.email }}
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-section">
                                {{ item.user.job_description }}
                            </div>
                        </div>
                    </el-popover>

                    <span class="name"> {{ item.user.name }} </span>
                </div>

                <div class="details">
                    <div v-for="day in item.work_days" :key="day">
                        <el-popover
                                placement="right-start"
                                :width="236"
                                trigger="click"
                                v-model:visible="usersPrioritiesPopover[item.user.email+day.week_day]"
                                transition=""
                                popper-class="priority-popover">
                            <template #reference>
                                <Circle class="pointer" :leftColor="getCircleColor('left', day)" :rightColor="getCircleColor('right', day)" :size="14" :onclick="() => getUserDayPriorities(selectedWeek, item.user, day)" />
                            </template>

                            <div class="popover">
                                <div class="top-section">
                                    <div class="title">{{ item.user.name.split(" ")[0] }}'s priorities</div>
                                    <div class="close icon" @click="usersPrioritiesPopover[item.user.email+day.week_day] = false">
                                        <i class="el-icon-close"></i>
                                    </div>
                                </div>
                                <div class="bottom-section" v-if="usersDaysPriorities && usersDaysPriorities.length">
                                    <p class="priority" v-for="priority in usersDaysPriorities" :key="priority.id">
                                        <input type="checkbox" :checked="priority.status === 'done'" disabled />
                                        <label>{{priority.description}}</label>
                                    </p>
                                </div>
                                <div class="bottom-section" v-else>
                                    <p class="priority-message" >
                                        <label>{{ prioritiesMessage }}</label>
                                    </p>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <NoContent v-else text="Standuply not available" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Circle from './Circle.vue';
import NoContent from "./NoContent";

export default {
        name: "Standuply",
        components: {
            Circle, NoContent
        },
        data() {
            return {
                isPopoverVisible: false,
                usersPrioritiesPopover: [],
                usersDaysPriorities: [],
                prioritiesMessage: "",
                workWeek: [],
                selectedWeek: []
            }
        },
        computed: {
            ...mapGetters({
                standuply: 'standuply',
            }),
            getCurrentDay: function() {
                let day = new Date().getDay();
                if(day && day < 6) return `left: calc(100% - 23px - ${(6 - day) * 22}px)`;
                return 'display: none';
            }
        },
        created() {
            this.selectedWeek = this.standuply[0]
        },
        methods: {
            getCircleColor: function(type, dayReport) {
                let report = type === 'left' ? dayReport.standuply_report_morning : dayReport.standuply_report_evening;

                switch (report) {
                    case 'missed':
                        return "#AF6555";
                    case 'holiday':
                        return "#E0C183";
                    case 'submitted':
                        return "#8CBF7F";
                    case 'future':
                    default:
                        return dayReport.week_day === (new Date()).getDay() ? "white" : "#E7E7E7";
                }
            },
            getPrioritiesMessage: function(dayReport, name) {
                let weekdays=new Array(5);
                weekdays[1]="Monday";
                weekdays[2]="Tuesday";
                weekdays[3]="Wednesday";
                weekdays[4]="Thursday";
                weekdays[5]="Friday";

                switch (dayReport.standuply_report_morning) {
                    case 'future':
                        return "No priorities set - Future checkin";
                    case  'holiday':
                        return "No priorities set - On vacation";
                    default:
                        return name + " hasn't set any priorities for " + weekdays[dayReport.week_day];
                }
            },
            getUserDayPriorities: function (selected_week, user, day) {
                this.usersDaysPriorities = [];
                this.prioritiesMessage = "Fetching priorities...";

                this.$store.dispatch('setUserDayPriorities', {email: user.email, week_day: day.week_day, week_year: selected_week.week_year, week_number: selected_week.week_number}).then(response => {
                    this.usersDaysPriorities = response;

                    if(this.usersDaysPriorities.length === 0) {
                        this.prioritiesMessage = this.getPrioritiesMessage(day, user.name.split(" ")[0])
                    }
                }).catch(() => {
                    this.usersDaysPriorities = [];
                    this.prioritiesMessage = "";
                });
            },
            changeWeek: function(week) {
                this.selectedWeek = week
            },
            isSelected: function(week) {
                if(this.selectedWeek.week_number === week.week_number && this.selectedWeek.week_year === week.week_year)
                    return "active";

                return "";
            },
            getSubmissionRateColor: function(submission_rate) {
                if(submission_rate  == 0) return "background-color: #C4C4C4";
                if(submission_rate  < 80) return "background-color: #F3E2DF; color: #744338";
                if(submission_rate < 100) return "background-color: #F8F1E3; color: #847046";
                if(submission_rate == 100) return "background-color: #E5F1E2; color: #4C6E44";

                return '#a4a4a4';
            },
            isCurrentWeek(week) {
                return this.standuply[0].week_number == week.week_number && this.standuply[0].week_year == week.week_year;
            }
        },
        watch: {
            standuply(newValue) {
                this.selectedWeek = newValue[0]
            }
        }
    }
</script>

<style scoped>
    .card-section {
        background-color: white;
        border-radius: 15px;
        margin-top: 16px;
        text-align: left;
        height: calc(100% - 16px);
    }

    .card-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64656F;
        padding: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #F3F3F3;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
    }

    .card-content {
        padding: 0 16px 16px 16px;
    }
    .card-content .avatar {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        background-color: transparent;
    }
    .standuply {
        display: flex;
        justify-content: space-between;
        background-color: #F3F3F3;
        border-radius: 34px;
        margin-bottom: 10px;
        padding: 5px;
        color: #64656F;
    }
    .standuply > div {
        display: flex;
        align-items: center;
        font-size: 12px;
    }


    .tooltip .top-section {
        display: flex;
        align-items: center;
    }
    .tooltip .top-section > div {
        display: inline-block;
    }
    .tooltip .avatar {
        margin-right: 10px;
        width: 40px;
        height: 40px;
    }
    .tooltip .name {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
    }
    .tooltip .job {
        font-weight: 500;
        font-size: 12px;
    }

    .tooltip .middle-section {
        margin-top: 12px;
        padding: 15px 0 0 0;
        border-top: 1px solid #F3F3F3;
        border-bottom: 1px solid #F3F3F3;
        font-size: 12px;
    }
    .tooltip .middle-section > div {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }
    .tooltip .icon {
        margin-right: 16px;
    }
    .tooltip .icon::after {
        background-color: #F9F9F9;
    }

    .tooltip .bottom-section {
        font-size: 12px;
        padding: 18px 0 5px 0;
        word-break: break-word;
        text-align: left;
    }

    .icon {
        position: relative;
        display: inline-block;
        margin: 0 6px 0 4px;
        border: none;
        background: transparent;
    }
    .icon::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: #f3f3f3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: .3s;
    }

    .icon:hover::after {
        background-color: #eaeaea;
    }

    .icon a {
        text-decoration: none;
        color: #64656f;
    }

    .icon i {
        position: relative;
        z-index: 9;
        font-size: 10px;
    }

    .info-button {
        cursor: pointer;
        margin-left: auto;
    }

    .popover .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        border-bottom: 1px solid #F3F3F3;
        padding: 10px 16px 16px 16px;
        margin: 0 -12px;
    }
    .popover .close {
        cursor: pointer;
    }
    .popover .close i {
        position: relative;
        z-index: 9;
        font-weight: bold;
        vertical-align: middle;
        font-size: 12px;
        line-height: 12px;
    }
    .popover .bottom-section {
        padding: 0 4px 4px 4px;
    }
    .popover.legend .bottom-section {
        padding: 20px 4px 4px 4px;
    }
    .popover .bottom-section > div {
        background-color: #F3F3F3;
        border-radius: 34px;
        font-size: 12px;
        padding: 12px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    .card-section > .el-icon-caret-bottom {
        color: #AF6555;
        position: relative;
        top: -8px;
    }

    .standuply .name {
        height: 18px;
        overflow: hidden;
        line-height: 15px;
    }

    .priority {
        font-size: 12px;
        line-height: 14px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        margin: 16px 0 0 0;
        column-gap: 24px;
        position: relative;
    }

    .priority-message {
        font-size: 12px;
        line-height: 12px;
        margin: 16px 0 0 0;
        text-align: center;
    }

    .priority label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0 0 20px;
    }
    .priority input {
        appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
    }
    .priority input:focus {
        outline: none;
    }
    .priority input:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: rgba(243, 243, 243, 0.5);
    }
    .priority input:before {
        content: '';
        position: absolute;
        border: 1px solid #64656F;
        border-top-width: 0;
        border-left-width: 0;
        z-index: 9;
        width: 3px;
        height: 7px;
        top: 1px;
        left: 6px;
        transform: rotate(45deg) scaleY(1);
    }
    .priority input:checked:before {
        border-color: white;
    }
    .priority input:checked:after {
        background-color: #8CBF7F;
    }
    .pointer {
        cursor: pointer;
    }


    .dropdown-button {
        background-color: #f3f3f3;
        border-radius: 15px;
        border: none;
        font-size: 11px;
        color: #64656f;
        padding: 5px 8px;
        min-height: auto;
        transition: .3s;
        margin-left: 5px;
    }

    .dropdown-button:hover {
        background-color: #eaeaea;
    }
    .dropdown-button .el-icon-caret-bottom {
        margin-left: 5px;
        margin-right: -2px;
        top: auto;
    }
    .el-dropdown-menu {
        width: 225px;
        padding: 2px 0;
        max-height: 452px;
        overflow-y: scroll;
    }
    .el-dropdown-menu::-webkit-scrollbar {
        width: 3px;
        border-radius: 33px;
    }
    .el-dropdown-menu::-webkit-scrollbar-thumb {
        background-color: #C4C4C4;
        border-radius: 32px !important;
    }
    .el-dropdown-menu__item {
        border-radius: 5px;
        margin: 0 2px;
        line-height: 32px;
        padding: 0 12px;
    }
    .el-dropdown-menu__item:hover {
        background: #EAEAEA !important;
    }
    .el-dropdown-menu__item.active {
        background: #64656F !important;
        color: white !important;
    }
    .el-dropdown-menu__item:not(.active) .date {
        color: #a2a2a2;
    }

    .week {
        font-size: 12px;
    }
    .week .submission_rate {
        font-size: 9px;
        border-radius: 10px;
        padding: 3px 2px;
        width: 25px;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        text-align: center;
        font-weight: bold;
    }
    .standuply-dropdown {
        overflow: auto;
    }
</style>
