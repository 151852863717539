<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    }
  },
  methods: {
    login: async function() {
      let googleToken = new URLSearchParams(window.location.search).get('code');
      if(googleToken) {
        await this.$store.dispatch('auth/login', googleToken).then(async () => {
          await this.$store.dispatch('compass');
        }).then(async () => {
          await this.$router.push('/');
        })
      } else {
        await this.$store.dispatch('auth/startLogin');
      }
    }
  },
  created: async function () {
    if(!this.loggedIn) {
      this.login();
    } else if(this.loggedIn) {
        // Created method runs before router setup; small hack to overcome that
        const unwatch = this.$watch(
                () => this.$route,
                (route) => {
                    let teamId = route.params.teamId;
                    this.$store.dispatch('compass', teamId);

                    unwatch()
                }
        )
    }

    this.$store.dispatch('systemStatus');
  }
}

</script>

<style>
body {
  margin: 0;
}

.el-popper.is-light {
  background: #FFFFFF !important;
  box-shadow: 0px 4px 11px rgba(170, 178, 189, 0.4) !important;
  border: none !important;
  color: #64656F !important;
}

.priority-popover {
  width: 379px !important;
  box-sizing: border-box !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  background-color: #f3f3f3;
  min-height: 100vh;
}

.el-scrollbar {
  background: #FFFFFF !important;
  box-shadow: 0px -1px 11px rgba(170, 178, 189, 0.4) !important;
  border-radius: 8px !important;
  border: none !important;
}

#el-drawer__title {
  display: none;
}

.el-popover, .el-popper {
  border-radius: 8px !important;
}
.el-button--small.is-circle i.el-icon-s-grid {
  color: #64656F !important;
}
.el-drawer__close-btn i {
  position: relative;
  z-index: 9;
  font-weight: bold;
}
</style>
