<template>
    <div class="card-section">
        <div class="card-title">
            <div class="title">
                <span>Apps</span>
                <span>{{enabledIntegrations?.length}}/5</span>
            </div>
            <div class="desc">
                Pin to the dashboard up to 5 apps
            </div>
            <i class="fas fa-times" @click="closeDrawer()"></i>
        </div>
        <div class="card-content">
            <div class="search-bar">
                <el-input
                    placeholder="Search for an app"
                    v-model="searchText"
                    @keyup="searchAction">

                    <template #prepend>
                        <!--TODO: Replace with pro version icon-->
                        <i class="fas fa-search"></i>
                    </template>
                </el-input>
            </div>

            <div class="integrations">
                <div v-for="(integration, key) in foundIntegrations" :key="key" class="integration">
                    <el-badge class="item">
                        <a :href="integration.url" class="avatar-link">
                            <el-avatar :size="24" shape="square" fit="cover" :src="integration.logo_url"></el-avatar>
                        </a>
                    </el-badge>
                    <div class="content">
                        <div class="name"><a :href="integration.url" target="_blank">{{integration.text}}</a> </div>
                        <div class="desc"> {{integration.description}} </div>
                    </div>
                    <el-tooltip v-if="enabledIntegrations?.length >= 5" content="You can only pin up to 5 applications." trigger="click" placement="bottom" effect="light">
                        <span class="icon" @click="selectIntegration(integration)">
                            <!--TODO: Replace with pro version icon-->
                            <i class="fas fa-thumbtack" v-if="integration.enabled"></i>
                            <i class="far fa-thumbtack" v-else></i>
                        </span>
                    </el-tooltip>
                    <span v-else class="icon" @click="selectIntegration(integration)">
                        <!--TODO: Replace with pro version icon-->
                        <i class="fas fa-thumbtack" v-if="integration.enabled"></i>
                        <i class="far fa-thumbtack" v-else></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "Standuply",
        data() {
            return {
                searchText: '',
                foundIntegrations: []
            }
        },
        computed: {
            ...mapGetters({
                integrations: 'integrations'
            }),
            enabledIntegrations: function() {
                if(this.integrations == null) return [];
                return this.integrations.filter(priority => priority.enabled === true);
            },
        },
        methods: {
            searchAction: function() {
                this.foundIntegrations = [];
                this.integrations.forEach(integration => {
                    if(integration.name.includes(this.searchText) || integration.text.includes(this.searchText)) {
                        this.foundIntegrations.push(integration);
                    }
                });
            },
            selectIntegration: function(integration) {
                let newState = integration.enabled ? 'disabled' : 'enabled';
                if(newState === 'disabled' || this.enabledIntegrations.length < 5) {
                    this.$store.dispatch('updateIntegrationState', {name: integration.name, status: newState});

                    // TODO: update this value after updateIntegrationState function returns success
                    integration.enabled = !integration.enabled;
                }
            },
            closeDrawer: function() {
                this.$emit("closeDrawer");
            }
        },
        created: function() {
            this.foundIntegrations = this.integrations;
        }
    }
</script>

<style scoped>
    .card-section {
        background-color: white;
        text-align: left;
        width: 376px;
        max-width: 100vw;
    }

    .card-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64656F;
        padding: 16px;
        border-bottom: 1px solid #F3F3F3;
        position: relative;
        margin-right: 20px;
    }
    .card-title .title span:first-of-type {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        padding-right: 8px;
    }
    .card-title .title span:last-of-type {
        background: #F3F3F3;
        border-radius: 4px;
        font-size: 9px;
        line-height: 11px;
        color: #64656F;
        padding: 2px 4px;
    }
    .card-title .desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
    }
    .card-title i {
        position: absolute;
        right: 0;
        font-size: 18px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .el-input {
        line-height: 0;
    }
    .el-input >>> .el-input__inner {
        padding: 5px 11px 5px 30px;
        background-color: #F3F3F3;
        border-radius: 20px;
        height: 32px;
        font-size: 12px;
        border: none;
    }
    .el-input >>> .el-input__suffix {
        right: auto;
        left: 5px;
        display: flex;
        align-items: center;
    }
    .el-input >>> .el-input-group__prepend {
        padding: 0;
        background-color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border: none;
        position: initial;
    }
    .el-input i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
    }


    .search-bar {
        padding: 8px 16px;
        border: 1px solid #EBEBEB;
    }

    .integrations {
        background: #F4F4F4;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        padding: 16px;
    }
    .integrations::-webkit-scrollbar {
        width: 0;
    }

    .integration {
        background: #FFFFFF;
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .integration .avatar-link {
        width: 24px;
        height: 24px;
    }

    .integration .el-avatar {
        background: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    div.el-badge {
        background: url("/img/static/logo-frame.svg") no-repeat;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .integration .icon {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 12px;
        cursor: pointer;
        color: #64656f;
    }
    .integration .icon::after {
        content: '';
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: #E5E6E8;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }
    .integration .icon img, .integration .icon i {
        position: relative;
        z-index: 9;
        font-size: 14px;
    }

    .integration .content {
        width: calc(100% - 72px);
    }
    .integration .name a {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #64656F;
        text-decoration: none;
    }
    .integration .desc {
        font-size: 10px;
        line-height: 10px;
        color: #64656F;
        padding-right: 15px;
    }
</style>
