<template>
  <div id="home">
    <div id="content">
      <div class="home-left">
        <div class="search-bar-wrapper">
          <SearchBar/>
          <UsageStats v-if="isCompassLoaded && loggedIn" />
          <Mrr  v-if="isCompassLoaded && loggedIn" />
        </div>
        <Header v-if="isCompassLoaded" />
        <div class="content" v-if="isCompassLoaded && loggedIn">
          <div class="left-section" :class="'display-type-' + displayType">
            <UserPriorities v-if="displayType !== 'other'" />
            <TeamPriorities />
            <TeamOkrs />
          </div>

          <div class="right-section">
            <Standuply />
          </div>
        </div>

        <div v-if="!loggedIn">
          <div class="login-widget">
            <div class="login-top">
              <img src="../assets/logo.svg" alt="Logo">
              <button class="login-button" @click="googleLogin()"> <i class="fas fa-arrow-right"></i> Login </button>
            </div>
            <div v-if="loginErrorMessage != null && loginErrorMessage != ''" class="login-errors">
              <i class="fas fa-exclamation-triangle"></i>
              <span class="error">{{ loginErrorMessage }}</span>
            </div>
          </div>


        </div>
      </div>

      <div class="home-right" v-if="loggedIn">
        <News v-if="isCompassLoaded" />
      </div>
    </div>

  </div>

  <Footer />
</template>

<script>
// @ is an alias to /src
import SearchBar from '@/components/SearchBar.vue'
import Header from '@/components/Header.vue'
import Standuply from '@/components/Standuply.vue'
import UserPriorities from '@/components/UserPriorities.vue'
import TeamPriorities from '@/components/TeamPriorities.vue'
import News from '@/components/News.vue'
import { mapGetters } from 'vuex'
import TeamOkrs from '@/components/TeamOkrs.vue'
import Footer from '@/components/Footer.vue'
import Mrr from '@/components/Mrr.vue'
import UsageStats from "@/components/UsageStats.vue";

export default {
  name: 'Home',
  components: {
      UsageStats,
    SearchBar, Header, Standuply, News, UserPriorities, TeamPriorities, TeamOkrs, Footer, Mrr
  },
  methods: {
    googleLogin: function () {
      return window.location.href = this.googleUrl;
    }
  },
  computed: {
    ...mapGetters({
      userPriorities: 'userPriorities',
      googleUrl: 'googleUrl',
      loggedIn: 'auth/loggedIn',
      isCompassLoaded: 'auth/isCompassLoaded',
      currentUser: 'loggedUser',
      displayType: 'displayType',
    }),
    loginErrorMessage() {
      return this.$store.state.auth.loginError;
    }
  }
}
</script>

<style scoped>
  #home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }
  #content {
    display: flex;
    flex: 1;
  }
  .home-left {
    width: calc(100% - 350px);
    padding: 16px 16px 49px 32px;
    margin: 0 auto;
  }
  .home-right {
    width: 350px;
    padding: 0 0 28px 16px;
  }

  .content {
    display: flex;
  }
  .right-section {
    width: calc(25% - 8px);
    padding-left: 8px;
    min-width: 260px;
  }
  .left-section {
    width: calc(75% - 8px);
    padding-right: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .left-section > div {
    width: calc(50% - 8px);
  }
  .left-section > div:last-of-type {
    width: 100%;
  }

  .display-type-other > div:first-of-type {
    width: calc((25 / 100 * 135%) - 8px);
  }
  .display-type-other > div:last-of-type {
    order: -1;
    width: calc(100% - (25 / 100 * 135%) - 8px);
  }

  @media screen and (max-width: 1024px) {
    .left-section .card-section {
      width: 100%;
    }
  }
  @media screen and (max-width: 850px) {
    #content {
      display: block;
    }
    .home-left {
      width: calc(100% - 32px);
      padding: 16px;
    }
    .home-right {
      width: calc(100% - 32px);
      padding: 0 16px 16px 16px;
    }
    .home-right .card-section {
      border-radius: 15px;
    }
    .search-bar-wrapper {
      flex-direction: column;
    }
    .search-bar-wrapper >>> .el-input{
      margin-bottom: 8px;
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .home-left .content {
      display: block;
    }
    .left-section, .right-section {
      width: 100%;
    }
  }

  .login-widget {
    width: 370px;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 0 auto;
  }

  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #F3F3F3;
    border-radius: 36px;
    cursor: pointer;
    width: 88px;
    height: 32px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #64656F;
  }
  .login-button:focus {
    outline: none;
  }
  .login-button:active {
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 25%);
  }
  .login-button i {
    font-size: 10px;
    line-height: 10px;
    color: #64656F;
    margin-right: 5px;
  }
  .login-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    height: 80px;
    box-sizing: border-box;
  }
  .login-top > img {
    height: 16px;
  }

  .login-errors {
    padding: 18px 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-align: left;
    color: #AF6555;
    border-top: 1px solid #F3F3F3;
  }

  .login-errors i {
    font-size: 12px;
    line-height: 12px;
    color: #AF6555;
    padding: 10px 9px;
    margin-right: 8px;
    background: #F3E2DF;
    border-radius: 39px;
  }

  .search-bar-wrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

</style>
