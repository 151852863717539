<template>
    <div v-if="company_info" class="mrr-pill">
        <div class="mrr-text">
            MRR
            <el-tooltip :popper-class="'addBreakLine'" :content="getMrrInfoTooltipText" placement="bottom" effect="light">
                <span class="mrr-text-tooltip"><i class="far fa-info-circle"></i></span>
            </el-tooltip>
        </div>
        <el-tooltip :content="getMrrPillToolTipText" placement="bottom" effect="light">
            <div class="mrr-info">
                <div class="mrr-value">&euro;{{$shortenNumbers(company_info.mrr)}}</div>
                <div :class="{'trend-negative':company_info.mrr_trend_percentage < 0, 'trend-positive':company_info.mrr_trend_percentage > 0} " class="mrr-trend">
                    <div class="mrr-percentage">
                        {{$percentageFormat(company_info.mrr_trend_percentage)}}
                    </div>
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "Mrr",
        computed: {
            ...mapGetters({
                company_info: 'companyInfo',
            }),
            getMrrPillToolTipText() {
                const dateToday = new Date();
                const monthAndDayToday = dateToday.toLocaleString('default', { month: 'short', day: 'numeric' });
                const monthAndDayTodayUpper = monthAndDayToday.toUpperCase();
                dateToday.setDate(1); // going to 1st of the month
                dateToday.setHours(-1);
                const monthAndDayLastMonth = dateToday.toLocaleString('default', { month: 'short', day: 'numeric' });
                const monthAndDayLastMonthUpper = monthAndDayLastMonth.toUpperCase();
                return `Last day of the prev month vs today. (${monthAndDayLastMonthUpper} - ${monthAndDayTodayUpper})`;
            },
            getMrrInfoTooltipText() {
                return 'MRR = active subscriptions (including \n the advance payment discounts)';
            }
        },
    }
</script>

<style scoped>
    .mrr-pill{
        display:flex;
        align-items: center;
        padding: 8px 8px 8px 16px;
        background-color: #fff;
        border-radius: 54px;
        min-width: 162px;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #64656F;
    }
    .mrr-info{
        padding: 4px 4px 4px 8px;
        background-color: #f9f9f9;
        border-radius: 55px;
        display:flex;
        align-items: center;
        cursor: pointer;
    }
    .mrr-value {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #64656F;
    }
    .mrr-trend {
        background: #eaeaea;
        border-radius: 18px;
        margin-left: 4px;
        display: flex;
        padding: 0px 4px;
        height: 16px;
        align-items: center;
        justify-content: center;
        width: 36px;
    }
    .mrr-percentage {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: -0.02em;
    }
    .mrr-text-tooltip {
        cursor: pointer;
    }
    .mrr-trend.trend-positive{
        color: #72A566;
        background: #E5F1E2
    }
    .mrr-trend.trend-negative {
        color: #AF6555;
        background: #F3E2DF
    }
</style>