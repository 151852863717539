import Cookies from "js-cookie";

export function getInitialState() {
    let token = localStorage.getItem('authorization_token');

    if (!token) {
        var authCookie = Cookies.get('auth-token');

        if (authCookie) {
            token = authCookie;
            localStorage.setItem('authorization_token', token)
        }
    }

    const initialState = token 
    ? { loggedIn: true, token: token, isCompassLoaded: false }
    : { loggedIn: false, token: null, isCompassLoaded: false };

    return initialState;
}

export default function () {
    return getInitialState()
}
