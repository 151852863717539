<template>
    <div class="footer">
        <div class="start-section">
          <div class="system-status">
            <Circle :size="12" :isFullyFilled="true" :color="getSystemStatusColor(systemStatus?.status.indicator)" />
            <a href="https://status.seomonitor.com/" v-html="systemStatus?.status.description"></a>
          </div>
        </div>
        <div class="middle-section">
            <a v-for="link in footerLinks?.center" :key="link" :href="link.url" target="_blank">
                <i :class="`fas fa-${link.icon_fa}`" v-if="link.icon_fa"></i>
                <img class="icon" :src="link.icon_url" alt="icon" v-if="link.icon_url">
                {{ link.text }}
            </a>
        </div>
        <div class="end-section">
            <a id="reports-a-bug-bug" class="cursor-pointer">
                <i class="fa fa-bug"></i> <span>Report Bug</span>
            </a>
        </div>
    </div>
</template>

<script>
import Circle from '@/components/Circle.vue'
import {mapGetters} from "vuex";

export default {
    name: 'Footer',
    components: {
        Circle
    },
    computed: {
        ...mapGetters({
            footerLinks: 'footerLinks',
            systemStatus: 'systemStatus',
            currentUser: 'loggedUser',
        })
    },
  methods: {
    getSystemStatusColor: function (indicator) {
      let color = '#a4a4a4';
      switch(indicator) {
        case 'none':
          color = '#2ecc71';
          break;
        case 'critical':
          color = '#e74c3c';
          break;
        case 'major':
          color = '#e67e22';
          break;
        case 'minor':
          color = '#f1c40f';
            break;
        case 'maintenance':
          color = '#2777ff';
          break;
      }

      return color;
    },
    loadUsersnap: function() {
        if (!this.currentUser) {
            setTimeout(this.loadUsersnap, 1000);
            return;
        }

        window.usersnap_email = this.currentUser?.email;

        // Bind the Usersnap load function to window
        window.onUsersnapCXLoad = function(api) {
            api.init({
                user: {
                    email: window.usersnap_email
                },
            });

            document.getElementById('reports-a-bug-bug').addEventListener('mouseup', function () {
                api.show('859beb61-5631-4343-b450-8b6555ccfa10').then((widgetApi) => widgetApi.open())
            });
        }

        var scripts = [
            "https://widget.usersnap.com/global/load/f885987a-dd2a-443f-9486-ba93dbd9f596?onload=onUsersnapCXLoad"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    }
  },
    created: function() {
        this.$store.dispatch('systemStatus');
        this.loadUsersnap();
    }
}
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #313438;
    color: #A4A4A4;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    padding: 4px 0;
    gap: 16px;
}
.start-section, .middle-section, .end-section {
    display: flex;
}

.system-status a {
  text-decoration: none;
  color: #A4A4A4;
}

.start-section {
    background-color: rgba(241, 241, 241, 0.08);
    border-radius: 70px;
    padding: 6px 10px 5px 10px;
    margin-left: 10px;
}

.start-section > a, .middle-section > a, .end-section > a {
    background-color: rgba(241, 241, 241, 0.08);
    border-radius: 70px;
    padding: 4px 8px;
    text-decoration: none;
    color: #A4A4A4;
    margin: 0 2px;
    transition: 0.5s;
}

.start-section > a:hover, .middle-section > a:hover, .end-section > a:hover {
    background: rgba(241, 241, 241, 0.24);
}

.end-section {
    margin-right: 16px;
}

.icon::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgba(241, 241, 241, 0.08);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: 0.5s;
}

.icon:hover::after {
    background: rgba(241, 241, 241, 0.24);
}

.icon {
    position: relative;
    z-index: 9;
    width: 14px;
    height: 16px;
    object-fit: contain;
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .footer {
        padding: 16px;
        flex-direction: column;
    }
    .middle-section, .end-section {
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }
}
</style>
