<template>
    <div v-if="app_stats?.usage?.campaigns_billed" class="stat-pill">
        <div class="stat-text">
            # campaigns
            <el-tooltip :popper-class="'addBreakLine'" :content="getTrackedCampaignsCountTooltipText" placement="bottom" effect="light">
                <span class="stat-text-tooltip"><i class="far fa-info-circle"></i></span>
            </el-tooltip>
        </div>
        <el-tooltip :content="getTrackedCampaignsCountText(app_stats?.usage?.campaigns_billed ?? 0, app_stats?.usage?.campaigns_with_traffic ?? 0)" placement="bottom" effect="light">
            <div class="stat-info">
                <div class="stat-value">{{$shortenNumbers(app_stats?.usage?.campaigns_billed)}}</div>
            </div>
        </el-tooltip>
    </div>
    <div v-if="app_stats?.usage?.keywords_billed" class="stat-pill">
        <div class="stat-text">
            # keywords
            <el-tooltip :popper-class="'addBreakLine'" :content="getTrackedKeywordsCountTooltipText" placement="bottom" effect="light">
                <span class="stat-text-tooltip"><i class="far fa-info-circle"></i></span>
            </el-tooltip>
        </div>
        <el-tooltip :content="getTrackedKeywordsCountText(app_stats?.usage?.keywords_billed ?? 0, app_stats?.usage?.keywords_magic ?? 0)" placement="bottom" effect="light">
            <div class="stat-info">
                <div class="stat-value">{{$shortenNumbers(app_stats?.usage?.keywords_billed)}}</div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "UsageStats",
        computed: {
            ...mapGetters({
                app_stats: 'appStats',
            }),
            getTrackedCampaignsCountTooltipText() {
                return '# of Tracked Campaigns';
            },
            getTrackedKeywordsCountTooltipText() {
                return '# of Tracked Keywords';
            }
        },
        methods: {
            getTrackedCampaignsCountText(cntBilled, cntWithTraffic) {
                return (new Intl.NumberFormat().format(cntBilled)) + ' tracked campaigns ' +
                    '(' + (new Intl.NumberFormat().format(cntWithTraffic)) + ' traffic campaigns' + ')';
            },
            getTrackedKeywordsCountText(cntBilled, cntMagic) {
                return (new Intl.NumberFormat().format(cntBilled)) + ' tracked keywords ' +
                    '(' + (new Intl.NumberFormat().format(cntMagic)) + ' magic keywords' + ')';
            },
        }
    }
</script>

<style scoped>
    .stat-pill{
        display:flex;
        align-items: center;
        margin: 0 8px 0 0;
        padding: 8px 8px 8px 16px;
        background-color: #fff;
        border-radius: 54px;
        min-width: 162px;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #64656F;
    }
    .stat-info{
        padding: 4px 8px 4px 8px;
        background-color: #f9f9f9;
        border-radius: 55px;
        display:flex;
        align-items: center;
        cursor: pointer;
    }
    .stat-value {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #64656F;
    }
    .stat-text-tooltip {
        cursor: pointer;
    }
</style>