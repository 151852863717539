import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import installElementPlus from './plugins/element'




const app = createApp(App)
installElementPlus(app)
app.use(store).use(router).mount('#app')


app.config.globalProperties.$shortenNumbers = function (num, decimalPlaces = 1, return_zero = true) {
    if (isNaN(parseFloat(num))) {

        if (return_zero) return 0;
        if (num === 'N/A') return '-';
        return num;
    }
    num = parseFloat(num);
    var str,
        suffix = '';
    if (num === 0) {
        if (!return_zero) return '-';
        decimalPlaces = 0;
    }

    num = +num;

    var factor = Math.pow(10, decimalPlaces);

    if (num < 1000 && num > -1000) {
        if (num % 1 == 0) {
            str = num.toFixed(0);
        } else {
            str = num.toFixed(decimalPlaces);
        }
    } else if (num < 1000000 && num > -1000000) {
        // str = Math.floor(num / (1000 / factor)) / factor;
        str = ((num / (1000 / factor)) / factor).toFixed(decimalPlaces);
        suffix = 'K';
    } else if (num < 1000000000 && num > -1000000000) {
        // str = Math.floor(num / (1000000 / factor)) / factor;
        str = ((num / (1000000 / factor)) / factor).toFixed(decimalPlaces);
        suffix = 'M';
    } else if (num < 1000000000000 && num > -1000000000000) {
        // str = Math.floor(num / (1000000000 / factor)) / factor;
        str = ((num / (1000000000 / factor)) / factor).toFixed(decimalPlaces);
        suffix = 'B';
    } else if (num < 1000000000000000 && num > -1000000000000000) {
        // str = Math.floor(num / (1000000000000 / factor)) / factor;
        str = ((num / (1000000000000 / factor)) / factor).toFixed(decimalPlaces);
        suffix = 'T';
    }
    if (parseFloat(str) % 1 == 0) {
        str = parseFloat(str).toFixed(0);
    }
    if (((str > 100 && str < 1000) || (str < -100 && str > -1000)) && num > 999) {
        str = parseFloat(str).toFixed(0);
    }

    return str + suffix;
};

app.config.globalProperties.$percentageFormat = function (int, showSign = true, decimals = 1, showZero = false, showMultiplier = false) {

    if (int == null || int == '-' || isNaN(int)) {
        return '-';
    }

    if ((int <= 0.1 && int >= -0.1) && decimals > 1) {
        const multiplier = 10 ** decimals;
        int = Math.round(int * multiplier) / multiplier
    } else if (int > 10 || int < -10) {
        int = Math.round(int);
    } else {
        int = Math.round(int * 10) / 10
    }

    if (int === 0) {
        return showZero ? '0%' : '-';
    }

    let sign = (int > 0 && showSign) ? '+' : '';
    if (int > 99 && showMultiplier) return 'x' + parseInt((int / 100) + 1);
    // if(int > 200) return sign + parseInt(int/100) + 'x';

    return sign + int + '%';
};