import ApiHandler from '@/services/ApiHandler'

export async function login({ commit }, googleToken) {
    return ApiHandler.makeRequest('get', '/login', {'code': googleToken}).then(token => {
        localStorage.setItem('authorization_token', token.token);
        commit('loginSuccess', token.token);
        return Promise.resolve();
    }).catch(() => {
        localStorage.removeItem('authorization_token');
        commit('loginFailure', "We couldn't find this email in our database, please try again.");
        return Promise.reject();
    });
    
}

export async function startLogin({ commit }) {
    return ApiHandler.makeRequest('get', '/start-login').then(loginUrl => {
        localStorage.setItem('google_url', loginUrl?.login_url);
        commit('setGoogleUrl', loginUrl?.login_url, { root: true });

        return Promise.resolve();
    }).catch(() => {
        return Promise.reject('error');
    });
}