<template>
    <div class="card">
        <span class="beta">
            <span>beta</span>
        </span>
        <div class="card-top">
            <div>
                <h2>
                    Welcome back, {{firstName}}!
                    <el-tooltip v-if="currentUser && currentUser.has_report_due" content="15five overdue!" placement="bottom" effect="light">
                        <a href="https://my.15five.com/report/current/" target="_blank">
                            <el-tag class="overdue">15five overdue</el-tag>
                        </a>
                    </el-tooltip>
                </h2>
                <a href="https://calendar.google.com/" target="_blank">
                  <el-tag><i class="fas fa-calendar-alt"></i> {{getCurrentDay}} {{currentDate}}, Week {{currentWeek}}</el-tag>
                </a>
                <el-tooltip :content="remainingDays(currentUser?.holidays_left)" placement="bottom" effect="light">
                    <el-tag class="popover">
                        <a href="https://app.timetastic.co.uk/wallchart" target="_blank"><i class="el-icon-cold-drink"></i> {{ currentUser?.holidays_left }}d</a>
                    </el-tag>
                </el-tooltip>
            </div>
            <div>
                <IntegrationsList/>
            </div>
        </div>
        <el-divider>
            <TeamsMenu/>
        </el-divider>
        <div class="card-bottom">
            <p class="team-description">{{currentTeam?.description}}</p>
            <TeamKpis/>
        </div>
    </div>      
</template>

<script>
import { mapGetters } from 'vuex'

import IntegrationsList from './IntegrationsList.vue'
import TeamsMenu from './TeamsMenu.vue'
import TeamKpis from './TeamKpis.vue'

export default {
  name: 'Header',
  components: {
    IntegrationsList,
    TeamsMenu,
    TeamKpis
  },
  computed: {
    ...mapGetters({
      currentUser: 'loggedUser',
      currentTeam: 'userTeam'
    }),

    currentDate: function() {
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        let today = new Date();
        let day = today.getDate();
        let month = months[today.getMonth()];

        return day + " " + month;
    },

    currentWeek: function() {
        return new Date().getWeekNumber();
    },
    getCurrentDay: function() {
        let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        return days[new Date().getDay()];
    },
    firstName: function() {
        return this.currentUser?.name?.split(' ')?.[0]
    }
  },
  methods: {
      remainingDays: function(days) {
          if(days > 1) days += " days";
          else days += " day";

          return days + " remaining allowance in Timetastic.";
      }
  },

  mounted: function() {
  }
}
</script>

<style scoped>
.card {
    border-radius: 10px;
    background-color: white;
    text-align: left;
    position: relative;
}

.card-top {
    padding: 10px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
}
.card-bottom {
    padding: 10px 15px 6px 15px;
}

h2 {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    padding: 2px 0;
    color: #64656F;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
}
h2 a:focus {
    outline: none;
}

.card .el-tag.overdue {
    color: #AF6555;
    background-color: #F3E2DF;
    border-radius: 39px;
    top: 0;
    margin: 2px 0 3px 5px;
}

.card .el-tag {
    border-radius: 55px;
    background-color: #F9F9F9;
    color: #64656F;
    border: none;
    height: auto;
    padding: 4px 8px;
    position: relative;
    top: -3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

a + .el-tag {
    margin-left: 10px;
}
.el-tag a {
    text-decoration: none;
}
.el-tag a:focus {
    outline: none;
}
.el-tag a:active {
    color: #64656F;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}

.el-divider {
    background-color: #F3f3f3;
}

.el-divider >>> .el-divider__text {
    background-color: transparent;
}

.el-divider--horizontal {
    margin: 0;
}

.team-description {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    margin: 5px 0 10px;
    color: #64656F;
}
.popover:hover {
   background-color: #F3F3F3;
    cursor: pointer;
}

.popover a {
    color: #64656F;
}

.beta {
    position: absolute;
    background: #EEDFF3;
    border-radius: 39px;
    padding: 0 9px;
    display: flex;
    align-items: center;
    left: 15px;
    top: -8px;
    height: 16px;
    width: 23px;
}
.beta span {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.02em;
    color: #67287C;
    text-transform: uppercase;
}

@media screen and (max-width: 500px) {
    .card-top {
        display: block;
        text-align: center;
    }
}
</style>
