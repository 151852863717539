/** @format */

import { createStore } from 'vuex';
import ApiHandler from '../services/ApiHandler';

import auth from './Auth';

export default createStore({
    state: {
        compass: null,
        teams: [],
        systemStatus: null,
        googleUrl: '',
        usersDaysPriorities: [],
    },
    mutations: {
        setCompass: function (state, data) {
            state.compass = data;
        },
        setTeams: function (state, data) {
            // state.teams = data;
            // let newTeamsData = [];
            let productDevSubGroups = ['frontend', 'backend', 'reliability', 'product performance & education', 'swarm'];
            let revenueSubGroups = ['sales', 'customer operations'];

            let newTeamsData = data.filter(
                (item) => !productDevSubGroups.includes(item.name.toLowerCase()) && !revenueSubGroups.includes(item.name.toLowerCase())
            );
            // create subgroup for product group
            let indexOfProductGroup = newTeamsData.findIndex((team) => team.name.toLowerCase() == 'product development');
            newTeamsData[indexOfProductGroup].subTeams = data.filter((team) => productDevSubGroups.includes(team.name.toLowerCase()));
            // create subgroup for revenue group
            let indexOfRevenueGroup = newTeamsData.findIndex((team) => team.name.toLowerCase() == 'revenue');
            newTeamsData[indexOfRevenueGroup].subTeams = data.filter((team) => revenueSubGroups.includes(team.name.toLowerCase()));

            // reorder the array
          let initalTeamsData = [...newTeamsData];
            newTeamsData[0] = initalTeamsData.find((team) => team.name.toLowerCase() === 'leadership');
            newTeamsData[1] = initalTeamsData.find((team) => team.name.toLowerCase() === 'marketing');
            newTeamsData[2] = initalTeamsData.find((team) => team.name.toLowerCase() === 'revenue');
            newTeamsData[3] = initalTeamsData.find((team) => team.name.toLowerCase() === 'product led growth');
            newTeamsData[4] = initalTeamsData.find((team) => team.name.toLowerCase() === 'office');
            newTeamsData[5] = initalTeamsData.find((team) => team.name.toLowerCase() === 'product development');
            state.teams = newTeamsData;
        },
        setSystemStatus: function (state, data) {
            state.systemStatus = data;
        },
        setGoogleUrl: function (state, data) {
            state.googleUrl = data;
        },
        addNewPriority: function (state, newPriority) {
            state.compass?.user_priorities.push(newPriority);
        },
        setUserDayPriorities: function (state, data) {
            state.usersDaysPriorities[data.data.email + data.data.week_day + data.data.week_year + data.data.week_number] = data.priorities;
        },
    },
    actions: {
        compass: function ({ commit }, idTeam) {
            idTeam ? (idTeam = { team_id: idTeam }) : (idTeam = null);
            return ApiHandler.makeRequest('get', '/compass', idTeam).then((response) => {
                commit('setCompass', response);
                commit('auth/setCompassLoaded', true);

                // Redirect to Google when outside of working hours
                let startTime = new Date(response?.me?.working_hours?.start_time);
                let endTime = new Date(response?.me?.working_hours?.end_time);

                let now = new Date();

                if (now > endTime || now < startTime) {
                    // window.location.href = 'https://www.google.com';
                    return;
                }

                return Promise.resolve();
            });
        },
        teams: function ({ commit }) {
            return ApiHandler.makeRequest('get', '/teams').then((response) => {
                commit('setTeams', response);

                return Promise.resolve();
            });
        },
        systemStatus: function ({ commit }) {
            return ApiHandler.makeRequest('get', '/system-status').then((response) => {
                commit('setSystemStatus', response);

                return Promise.resolve();
            });
        },
        updateUserPriorityState: function ({ commit }, input) {
            return ApiHandler.makeRequest('put', '/user-priority/' + input.priorityId + '/' + input.status).then((response) => {
                console.log(commit, response);
                // commit('setTeams', response);

                return Promise.resolve();
            });
        },
        updateIntegrationState: function ({ commit }, input) {
            return ApiHandler.makeRequest('put', '/integration/' + input.name + '/' + input.status).then((response) => {
                console.log(commit, response);
                // commit('setTeams', response);

                return Promise.resolve();
            });
        },
        addNewPriority: function ({ commit }, input) {
            return ApiHandler.makeRequest('post', '/user-priority', { description: input }, null, true).then((response) => {
                commit('addNewPriority', {
                    id: response.id,
                    description: input,
                    status: 'not_done',
                });

                return Promise.resolve();
            });
        },
        setUserDayPriorities: function ({ commit }, data) {
            return ApiHandler.makeRequest('get', '/user-priorities', {
                email: data.email,
                week_day: data.week_day,
                week_year: data.week_year,
                week_number: data.week_number,
            })
                .then((response) => {
                    commit('setUserDayPriorities', {
                        priorities: response,
                        data: data,
                    });
                    return Promise.resolve(response);
                })
                .catch(() => {
                    return Promise.reject();
                });
        },
    },
    getters: {
        loggedUser: function (state) {
            return state.compass?.me;
        },
        userPriorities: function (state) {
            return state.compass?.user_priorities;
        },
        teamPriorities: function (state) {
            return state.compass?.team_priorities;
        },
        userTeam: function (state) {
            return state.compass?.team;
        },
        teams: function (state) {
            return state.teams;
        },
        systemStatus: function (state) {
            return state.systemStatus;
        },
        integrations: function (state) {
            return state.compass?.integrations;
        },
        kpis: function (state) {
            return state.compass?.kpis;
        },
        companyInfo: function (state) {
            return state.compass?.company_info;
        },
        appStats: function (state) {
            return state.compass?.app_stats;
        },
        displayType: function (state) {
            return state.compass?.display_type;
        },
        standuply: function (state) {
            return state.compass?.work_week;
        },
        teamOkrs: function (state) {
            return state.compass?.okrs;
        },
        footerLinks: function (state) {
            return state.compass?.footer_links;
        },
        googleUrl: function (state) {
            return state.googleUrl ? state.googleUrl : localStorage.getItem('google_url');
        },
        getUserDayPriorities: (state) => (user) => {
            return state.usersDaysPriorities[user.email + user.week_day];
        },
    },
    modules: {
        auth,
    },
});
