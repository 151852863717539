<template>
    <div class="card-section">
        <div class="card-content" id="news">

        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "News",
        computed: {
            ...mapGetters({
                loggedUser: 'loggedUser'
            })
        },
        created() {
            let fullName = this.loggedUser?.name.split(" ");
            if (this.loggedUser?.email) {
                window.beamer_config.user_email = this.loggedUser.email;
            }
            if (fullName?.[0]) {
                window.beamer_config.user_firstname = fullName[0].trim();
            }
            if (fullName?.[1]) {
                window.beamer_config.user_lastname = fullName[1].trim();
            }

            var scripts = [
                "https://app.getbeamer.com/js/beamer-embed.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        }
    }
</script>

<style scoped>
    .card-section {
        background-color: white;
        text-align: left;
        height: 100%;
        overflow: hidden;
    }

    .card-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64656F;
        padding: 16px;
        border-bottom: 1px solid #F3F3F3;
        letter-spacing: 0.05em;
    }

    .card-content {
      height: inherit;
    }

    @media screen and (max-width: 850px) {
        .card-section {
            height: auto;
        }
    }
</style>
