<template>
    <div class="wrapper" :style="wrapperCss">
        <div class="leftHalf" :style="leftCss"></div>
        <div class="rightHalf" :style="rightCss"></div>
    </div>
</template>

<script>
    export default {
        name: "Circle",
        props: {
            leftColor: String,
            rightColor: String,
            size: Number,
            isFullyFilled: Boolean,
            color: String
        },
        computed: {
            wrapperCss: function() {
                return `width: ${this.size}px; height: ${this.size}px`;
            },
            leftCss: function() {
                return `background-color: ${this.isFullyFilled ? this.color : this.leftColor};`;
            },
            rightCss: function() {
                return `background-color: ${this.isFullyFilled ? this.color : this.rightColor};`;
            }
        }
    }
</script>

<style scoped>
.wrapper {
  border-radius: 50%;
  overflow: hidden;
  margin: 0 8px 0 0;
  display: inline-block;
}

.leftHalf {
  display: inline-block;
  height: 100%; 
  width: 50%;
}

.rightHalf {
  display: inline-block;
  height: 100%; 
  width: 50%;
}
</style>
