export function token(state) {
    return state.token;
}

export function loggedIn(state) {
    return state.loggedIn;
}

export function isCompassLoaded(state) {
    return state.isCompassLoaded;
}
