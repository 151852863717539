<template>
    <div class="card-section">
        <div class="card-title">
            <span>Team quarterly okr's</span>
            <a class="icon" v-if="userTeam" :href="`https://my.15five.com/objectives/dashboard/?by_kr_owners=true&filters=redesign&state=current&user=g${userTeam.id}&view_type=flat`">
                <!--TODO: Replace with pro version icon-->
                <i class="fas fa-expand-alt"></i>
            </a>
        </div>
        <div class="card-content" v-if="teamOkrs && teamOkrs.length">
            <div v-for="item in teamOkrs" :key="item" class="item">
                <div class="color" v-bind:style="{ backgroundColor: getColor(item) }"></div>
                <div class="text">
                    <a :href="item.url" target="_blank">{{ item.description }}</a>
                </div>
                <div class="days-left">
                    <span>
                        <!--TODO: Replace with pro version icon-->
                        <i class="fas fa-clock"></i>
                        <span class="days" v-if="item.days_left === 0">Past Due</span>
                        <span class="days" v-else>{{ item.days_left }}D</span>
                    </span>
                </div>

                <div class="details">
                    <span> {{ item.percentage }}% </span>

                    <el-popover
                        placement="bottom-end"
                        :width="500"
                        trigger="click"
                        v-model:visible="teamOkrsPopover[item.url]"
                    >
                        <template #reference>
                            <span class="icon info-button" @click="teamOkrsPopover[item.url] = true">
                                <!--TODO: Replace with pro version icon-->
                                <i class="fas fa-info"></i>
                            </span>
                        </template>

                        <div class="popover">
                            <div class="top-section">
                                <div class="title">Key results</div>
                                <div class="close icon" @click="teamOkrsPopover[item.url] = false">
                                    <i class="el-icon-close"></i>
                                </div>
                            </div>
                            <div class="bottom-section">
                                <div v-for="key in item?.key_results" :key="key" class="key">
                                    <div class="description">
                                      <a :href="item.url" target="_blank">{{ key.description }}</a>
                                    </div>
                                    <div class="percentage">{{ key.percentage }}%</div>
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <NoContent v-else text="OKR's not available" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NoContent from "./NoContent";

export default {
    name: "Standuply",
    components: {
        NoContent
    },
    data() {
        return {
            msg: "msg",
            popover: "",
            visible: false,
            teamOkrsPopover: []
        }
    },
    computed: {
        ...mapGetters({
            teamOkrs: 'teamOkrs',
            userTeam: 'userTeam',
        }),
    },
    methods: {
        getColor(okr) {
            if(okr.color === "yellow") return "#E0C183";
            if(okr.color === "green") return "#8CBF7F";
            if(okr.color === "red") return "#AF6555";
        }
    }
}
</script>

<style scoped>
  .card-section {
    background-color: white;
    border-radius: 15px;
    margin-top: 16px;
    text-align: left;
  }

  .card-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #64656F;
    padding: 16px;
    text-transform: uppercase;
    border-bottom: 1px solid #F3F3F3;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: space-between;
  }
  .card-title > span {
      padding-right: 10px;
  }
  .card-title .icon {
      display: flex;
      align-items: center;
      color: #64656f;
  }
  .card-title .icon::after {
      width: 24px;
      height: 24px;
      margin-right: 9px;
      background-color: #f3f3f3;
      transition: .3s;
  }

  .card-title .icon:hover::after {
      background-color: #eaeaea;
  }

  .card-content {
    padding: 16px;
  }
  .item {
    background-color: #f0f0f1;
    margin-bottom: 5px;
    font-size: 12px;
    padding: 2px;
    border-radius: 20px 51px 51px 20px;
    border-color: transparent;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    align-items: center;
    column-gap: 8px;
    color: #64656F;
  }
  .item .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .item .text a {
    text-decoration: none;
    color: inherit;
  }
  .item .days-left {
      line-height: 0;
  }
  .item .days-left > span {
      background-color: #F9F9F9;
      border-radius: 55px;
      font-weight: 500;
      font-size: 8px;
      padding: 2px;
      display: flex;
      align-items: center;
      min-width: 27px;
      justify-content: space-between;
  }
  .item .days-left img, .item .days-left .days {
      display: inline-block;
      vertical-align: middle;
      line-height: 10px;
      padding-left: 2px;
  }
  .item .days-left img {
      margin-right: 3px;
  }
  .item .color {
      width: 6px;
      height: 100%;
      border-radius: 44px;
  }
  .item .details {
    background-color: #F9F9F9;
    border-radius: 51px;
    line-height: 24px;
    width: 70px;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
    justify-self: end;
  }
  .item .details span {
    letter-spacing: -0.04em;
  }
  .icon {
    position: relative;
    display: inline-block;
    margin: 0 11px 0 8px;
    border: none;
    background: transparent;
    text-decoration: none;
  }
  .icon:focus {
      outline: none;
  }
  .icon::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #E5E6E8;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
      transition: .3s;
  }
  .icon:hover::after {
      background-color: #eaeaea;
  }
  .icon i {
    position: relative;
    z-index: 9;
    font-size: 8px;
    vertical-align: middle;
  }
  .popover {
      padding: 4px;
  }
  .popover .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid #F3F3F3;
    padding: 4px 16px 12px 16px;
    margin: 0 -16px;
  }
  .popover .close {
    cursor: pointer;
      margin: 0 6px 4px 6px;
  }
  .popover .close >>> i {
    position: relative;
    z-index: 9;
    font-weight: bold;
    vertical-align: middle;
    font-size: 12px;
    line-height: 12px;
    top: -0.5px;
  }

  .popover .close::after {
      background-color: #F3F3F3;
  }

  .popover .close:hover::after {
      background-color: #eaeaea;
  }

  .popover .bottom-section {
    padding-top: 16px;
  }

  .popover .bottom-section .description a {
    text-decoration: none;
    color: inherit;
  }

  .popover .key {
    background-color: #F3F3F3;
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 16px;
    font-weight: 500;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;
  }
  .popover .key:last-of-type {
      margin: 0;
  }
  .popover .key .percentage {
    font-weight: normal;
  }
  .popover .icon::after {
    width: 24px;
    height: 24px;
  }

  .info-button {
      cursor: pointer;
  }

  .percentage {
      font-size: 12px;
  }
</style>
