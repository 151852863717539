import axios from 'axios';
import store from '@/store';

export default class APIHandler {

	static instance = null

	static makeRequest(method, url, options = null, headers = null, forceQueryParams = false) {
        let params = null;
        let data = null;

        if (["post", "patch", "put"].includes(method.toLowerCase())) {
            data = options;
        }
        
        if (method == "get" || forceQueryParams) {
            params = options;
            data = null;
		}
        headers = {...headers, 'Authorization': store.getters['auth/token']};

		return this.getInstance().request({ method, url, params, data, headers}).then(response => {
			return Promise.resolve(response.data);
		}).catch(error => {
			if(error?.response?.status === 401) {
				localStorage.removeItem('authorization_token');
				store.commit('auth/loginFailure');
			}
			return Promise.reject(error)
		});		
	}

	static getInstance() {
		if (this.instance == null) {

			this.instance = axios.create({
				baseURL: process.env.VUE_APP_COMPASS_API_URL,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			});
		}
		
		return this.instance;
    }
}
