<template>
    <div class="card-section">
        <div class="card-title">
            <span>My Today's Priorities
                <el-tooltip v-if="userPriorities && userPriorities.length" :content="completedPriorities + ' completed ' + (completedPriorities === 1 ? 'priority' : 'priorities') + ' out of ' + userPriorities.length" placement="bottom" effect="light">
                    <el-tag>{{ completedPriorities }}/{{ userPriorities.length }}</el-tag>
                </el-tooltip>
            </span>
            <a class="icon" v-if="userPriorities && userPriorities.length">
                <!--TODO: Replace with pro version icon-->
                <el-tooltip content="Copy today's priorites" placement="bottom" effect="light">
                    <i :class="'fas fa-' + copyPriorityIcon" :onclick="copyPriorities"></i>
                </el-tooltip>
            </a>
        </div>

        <div class="wrapper" v-if="userPriorities && userPriorities.length">
            <div class="card-content" ref="priorities">
                <div v-for="priority in userPriorities" :key="priority" class="item">
                    <input type="checkbox" @change="updateUserPriority(priority)" :checked="priority.status === 'done'" />
                    <label :for="priority.description">{{priority.description}}</label>
                </div>
            </div>
            <div class="card-bottom">
                <el-input placeholder="Add new priority" v-model="newPriority" @keyup.enter="addNewPriority">
                    <template #append>
                        <el-button type="danger" icon="el-icon-plus" circle :disabled="isDisabled" :onclick="addNewPriority"></el-button>
                    </template>
                </el-input>
            </div>
        </div>
        <NoContent v-else text="Your morning Standuply is not yet submitted" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoContent from "./NoContent";

export default {
    name: 'UserPriorities',
    components: {
        NoContent
    },
    computed: {
        ...mapGetters({
            userPriorities: 'userPriorities',
        }),
        isDisabled: function () {
            return this.newPriority.trim().length === 0;
        },
        completedPriorities: function() {
            if (this.userPriorities && this.userPriorities.length) {
                return this.userPriorities.filter(function (userPriority) {
                    return userPriority.status === 'done';
                }).length;
            }

            return 0;
        }
    },
    data: function() {
      return {
          newPriority: "",
          copyPriorityIcon: "copy"
      }
    },
    methods: {
        updateUserPriority: function(priority) {
            let status;
            priority.status === 'done' ? status = 'not_done' : status = 'done';
            this.$store.dispatch('updateUserPriorityState', {priorityId: priority.id, status});

            // TODO: update this value after updateUserPriorityState returns success
            priority.status = status;
        },
        addNewPriority: function () {
            this.$store.dispatch('addNewPriority', this.newPriority).then(() => {
                this.newPriority = "";
                let prios = this.$refs.priorities.children;
                let lastItem = prios[prios.length - 1];
                lastItem.scrollIntoView();
            });
        },
        copyPriorities: function(){
            this.copyPriorityIcon = "check";

            this.copyTextFormatted(this.$refs['priorities'].innerHTML);

            setTimeout(() => {
                this.copyPriorityIcon = "copy";
            }, 1000)
        },

        copyTextFormatted: function (html) {
            // Create container for the HTML
            var container = document.createElement('div')
            container.innerHTML = html;

            let items = container.querySelectorAll('.item');

            for (let i = 0; i < items.length; ++i) {
                items[i].querySelectorAll("input")[0].remove();
                if(this.userPriorities[i].status === "done")
                    items[i].innerHTML = "&#10003; " + items[i].innerHTML;
                else
                    items[i].innerHTML = "&#9723;" + items[i].innerHTML;
            }
            // Hide element
            container.style.position = 'fixed';
            container.style.pointerEvents = 'none';
            container.style.opacity = 0;

            // Detect all style sheets of the page
            var activeSheets = Array.prototype.slice.call(document.styleSheets)
                .filter(function (sheet) {
                    return !sheet.disabled
                });

            // Mount the container to the DOM to make `contentWindow` available
            document.body.appendChild(container);
            // Copy to clipboard
            window.getSelection().removeAllRanges();
            var range = document.createRange();
            range.selectNode(container);
            window.getSelection().addRange(range);
            document.execCommand('copy');
            for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;
            document.execCommand('copy');
            for (let i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;

            // Remove the container
            document.body.removeChild(container)
        }
    },
}
</script>

<style scoped>
    .el-input {
        background: #F3F3F3;
        border-radius: 52px;
    }

    .el-input-group >>> .el-input__inner,
    .el-input >>> .el-input-group__append{
        border: none;
        background: transparent;
    }

    .el-input >>> .el-input-group__append {
        padding: 0 4px 0 8px;
    }

    .el-input >>> .el-input__inner {
        height: 31px;
        color: #64656F;
        font-size: 12px;
        padding: 0 0 0 10px;
    }

    .el-input >>> .el-input__inner::placeholder {
        color: #64656F;
        font-size: 12px;
        opacity: 0.5;
    }

    .el-input-group__append button.el-button {
        background: #64656F;
        border-radius: 28px;
        width: 48px;
        margin: 4px 0;
        color: white;
        padding: 0;
        min-height: 23px;
        transition: 0.5s;
    }

    .el-input-group__append button.el-button:hover {
        background-color: rgba(100, 101, 111, .85);
    }

    .el-input-group__append button.el-button[disabled] {
        cursor: initial;
        opacity: 0.5;
        background: #64656F !important;
    }

    .card-section {
        background-color: white;
        border-radius: 15px;
        margin-top: 16px;
        text-align: left;
    }

    .card-bottom {
        border-top: 1px solid #F3F3F3;
        padding: 8px 16px;
    }

    .card-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64656F;
        padding: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #F3F3F3;
        letter-spacing: 0.05em;
        display: flex;
        justify-content: space-between;
    }

    .card-title .icon {
        position: relative;
        margin-right: 9px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #64656f;
        cursor: pointer;
    }
    .card-title .icon::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: #f3f3f3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: .3s;
    }
    .card-title .icon:hover::after {
        background-color: #eaeaea;
    }
    .card-title .icon i {
        position: relative;
        z-index: 9;
        font-size: 12px;
        outline: none !important;
    }

    .card-content {
        padding: 16px 16px 0 16px;
        max-height: 152px;
        overflow: auto;
        box-sizing: border-box;
    }

    /* width */
    .card-content::-webkit-scrollbar {
        width: 4px;
        height: 31px;
    }

    /* Track */
    .card-content::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    .card-content::-webkit-scrollbar-thumb {
        background: #C4C4C4;
        border-radius: 36px;
    }

    /* Handle on hover */
    .card-content::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .item {
        font-size: 12px;
        line-height: 13px;
        background: #F3F3F3;
        border-radius: 4px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        margin-bottom: 4px;
        column-gap: 5px;
        position: relative;
    }

    .item label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0 9px 36px;
    }

    .item:hover label {
        white-space: initial;
    }

    input {
        appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0 0 0 9px;
        cursor: pointer;
    }
    input:focus {
        outline: none;
    }
    input:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: white;
    }
    input:before {
        content: '';
        position: absolute;
        border: 1px solid black;
        border-top-width: 0;
        border-left-width: 0;
        z-index: 9;
        width: 3px;
        height: 7px;
        top: 10px;
        left: 6px;
        transform: rotate(45deg) scaleY(1);
    }
    input:checked:before {
        border-color: white;
    }
    input:checked:after {
        background-color: #8CBF7F;
    }
    .el-tag {
        border-radius: 55px;
        background-color: #F9F9F9;
        height: auto;
        padding: 4px 8px;
        display: inline-flex;
        align-items: center;
        color: #64656F;
        font-weight: 500;
        font-size: 9px;
        line-height: 10px;
        text-transform: uppercase;
        border: none;
    }
</style>
