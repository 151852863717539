<template>
    <div class="kpis">
        <el-tag v-for="kpi in kpiResults" :key="kpi">
            <Circle :leftColor="getColor(kpi.percentage)" :rightColor="getColor(kpi.percentage)" :size="12" />
            <span>{{kpi.description}} </span>
            <div class="trend-pill">
                <span class="percentage">{{kpi.percentage}}%</span>
                <el-tooltip
                    :disabled="kpi.percentage_change_date === null"
                    :content="kpi.percentage_change_date !== null ? 'Last updated on ' + (new Date(kpi.percentage_change_date)).toDateString() : ''"
                    placement="bottom"
                    effect="light"
                >
                    <el-tag :type="getTrendType(kpi.trend)" v-html="getTrendText(kpi)" class="trend" :class="{'negative': kpi.trend < 0, 'positive': kpi.trend > 0}"></el-tag>
                </el-tooltip>
            </div>
        </el-tag>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'

import Circle from './Circle.vue'

export default {
    name: 'TeamKpis',
    components: {
        Circle
    },
    computed: {
        ...mapGetters({
            kpis: 'kpis'
        }),
        kpiResults: function() {
            let keyResults = this.kpis?.map(function(kpi) {
                let color = kpi.color;
                return kpi.key_results.map(function(result) {
                    let keyResult = Object.assign({}, result);
                    keyResult.color = color;
                    return keyResult;
                });
            });

            return [].concat.apply([], keyResults);
        },
    },
    methods: {
        getColor(percentage) {
            if (percentage <= 50) {
                return '#AF6555';
            }
            if (percentage <= 80) {
                return '#E0C183';
            }

            return '#8CBF7F';
        },
        getTrendType(trend) {
            if (trend === 0) {
                return 'info';
            }

            if (trend <= 0) {
                return 'danger';
            }

            return 'success';
        },
        getTrendText(kpi) {
            if (kpi.trend === 0) {
                return '-';
            }

            if (kpi.warning) {
                return '<i class="fas fa-exclamation-triangle" style="color: #AF6555"></i>';
            }

            return kpi.trend + '%';
        }
    }
}
</script>

<style scoped>
.kpis {
    margin: 5px 0;
}
.el-tag {
    border-radius: 55px;
    background-color: #F9F9F9;
    height: auto;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    color: #64656F;
    font-weight: 500;
    font-size: 9px;
    line-height: 10px; 
    text-transform: uppercase; 
    border: none;
}

.el-tag {
    margin-right: 10px;
}
.el-tag:last-of-type {
    margin-right: 0;
}

.el-tag > .el-tag {
    margin-left: 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    padding: 3px 10px;
}

.percentage {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.trend-pill {
    background: #f3f3f3;
    border-radius: 50px;
    padding: 4px 4px 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 8px;
}
.trend-pill .trend {
    border-radius: 18px;
    margin-left: 4px;
    display: flex;
    padding: 0px 4px;
    height: 16px;
    align-items: center;
    justify-content: center;
    width: 36px;
}
.trend-pill .trend.negative {
    color: #AF6555;
    background: #F3E2DF;
}
.trend-pill .trend.positive {
    color: #72A566;
    background: #E5F1E2;
}
@media screen and (max-width: 450px) {
    .kpis > .el-tag {
        width: 100%;
        justify-content: center;
    }
}
</style>
