<template>
    <div class="integration-list">
        <el-badge v-for="integration in enabledIntegrations" :key="integration.name" :value="integration.number ? integration.number : null" class="item">
            <a :href="integration.url" class="avatar-link" target="_blank">
                <el-avatar :size="24" shape="square" :src="integration.logo_url"></el-avatar>
            </a>
        </el-badge>
        <el-button type="info" icon="fas fa-th" circle size="small" @click="apps = true"></el-button>
    </div>

    <el-drawer
        v-model="apps"
        direction="rtl"
        size="350">
        <Apps v-on:closeDrawer="closeAppsDrawer()" />
    </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import Apps from '@/components/Apps.vue';

export default {
  name: 'IntegrationsList',
  components: {
      Apps
  },
  data() {
    return {
      apps: false,
      gridData: [{
        date: '2016-05-02',
        name: 'Peter Parker',
          dress: 'Queens, New York City'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      integrations: 'integrations'
    }),

    enabledIntegrations: function() {
      if(this.integrations == null) return [];
      return this.integrations.filter(priority => priority.enabled === true);
    },
  },
  methods: {
    closeAppsDrawer: function() {
      this.apps = false;
    }
  }
}
</script>

<style scoped>
.integration-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.el-badge >>> .el-badge__content {
    top: 5px;
    background-color: #DE3711;
    width: 24px;
    right: 16px;
    padding: 0;
    font-size: 10px;
    height: 12px;
    line-height: 12px;
    font-weight: bold;
    letter-spacing: -0.02em;
}

.avatar-link {
    width: 24px;
    height: 24px;
}

.el-avatar {
    background: none;
    width: 24px;
    height: 24px;
}

div.el-badge {
    background: url("/img/static/logo-frame.svg") no-repeat;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.el-button {
    font-size: 16px;
}
.el-popover.el-popper.is-light {
    transform: none !important;
    right: 0 !important;
    left: auto !important;
}
.el-button--small.is-circle {
    background: #F3F3F3;
    border: none;
    transition: .3s;
}

.el-button--small.is-circle:hover {
    background-color: #eaeaea;
}

.el-button--small.is-circle >>> i {
    font-size: 17px;
    line-height: 17px;
    color: #64656F;
}

@media screen and (max-width: 500px) {
    .integration-list {
        justify-content: center;
        flex-wrap: wrap;
        padding: 24px 0 16px 0;
    }
}
</style>
