<template>
    <el-dropdown trigger="click">
        <el-button class="dropdown-button">
            <i :class="`far ${currentTeam?.icon}`"></i> {{currentTeam?.name}} Team<i class="el-icon-caret-bottom"></i>
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
              <div v-for="team in teams" :key="team?.name">
                <el-dropdown-item :icon="`fas ${team.icon}`"  @click.stop="changeTeam(team?.id)" :class="isSelected(team?.id)">
                        <div class="team-name">{{team?.name}} Team</div>
                </el-dropdown-item>
                <template v-if="team.subTeams">
                    <el-dropdown-item class="team-sub-group" :class="isSelected(group?.id)" :icon="`fas ${group.icon}`" v-for="group in team.subTeams" :key="group.name" @click.stop="changeTeam(group?.id)">
                        <div class="subgroup-team-name">{{group?.name}} Team</div>
                    </el-dropdown-item>
                </template>
              </div>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamsMenu',
  computed: {
    ...mapGetters({
      teams: 'teams',
      currentTeam: 'userTeam'
    }),
  },
  methods: {
      changeTeam: function(teamId) {
          this.$router.push({params: {teamId: teamId}});
          this.$store.dispatch('compass', teamId);
      },
      isSelected: function(teamId) {
          if(this.currentTeam.id === teamId)
              return "active";

          return "";
      }
  },
  created: function() {
    this.$store.dispatch('teams');
  }
}
</script>

<style scoped>
.dropdown-button {
    background-color: #f3f3f3;
    border-radius: 15px;
    border: none;
    font-size: 11px;
    color: #64656f;
    padding: 5px 8px;
    min-height: auto;
    transition: .3s;
}

.dropdown-button:hover {
    background-color: #eaeaea;
}
.dropdown-button .el-icon-caret-bottom {
    margin-left: 5px;
}

.el-dropdown-menu {
  width: 380px;
  padding: 0 0;
}

.el-dropdown-menu__item {
  padding: 15px;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.el-dropdown-menu__item.active >>> i {
    background: RGBA(255, 255, 255, .1);
    color: white;
}

.el-dropdown-menu__item.active {
    background: #64656F !important;
}

.el-dropdown-menu__item.active .team-name,
.el-dropdown-menu__item.active .subgroup-team-name,
.el-dropdown-menu__item.active .team-description {
    color: white !important;
}

.el-dropdown-menu__item >>> i {
  margin: 0;
  padding: 7px;
  font-size: 14px;
  margin: 0px 8px 0 0;
  background-color: #F3F3F3;
  border: none;
  color: #7D858F;
  border-radius: 50%;
}

.team-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #64656F;
  margin-bottom: 2px;
}

.team-sub-group{
  margin-left: 20px;
   font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #64656F;
  margin-bottom: 2px !important;
  padding: 10px 
}

.el-dropdown-menu__item.active .subgroup-team-name{
    color: white !important;
}

.team-description {
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #7D858F;
  max-height: 30px;
  overflow: hidden;
}
</style>
