import Cookies from 'js-cookie'

export function loginSuccess(state, token) {
    state.loggedIn = true;
    state.token = token;

    // TODO: This isn't the best place for this; move it
    Cookies.set('auth-token', token);
}

export function loginFailure(state, loginError = '') {
    state.loggedIn = false;
    state.token = null;
    state.isCompassLoaded = false;

    state.loginError = loginError;

    // TODO: This isn't the best place for this; move it
    Cookies.remove('auth-token');
}

export function logout(state) {
    state.loggedIn = false;
    state.token = null;
    state.isCompassLoaded = false;

    // TODO: This isn't the best place for this; move it
    Cookies.remove('auth-token');
}

export function setCompassLoaded(state, loaded) {
    state.isCompassLoaded = loaded;
}
