<template>
    <div class="card-section">
        <div class="card-title">
            <span>Team weekly Priorities</span>
            <a v-if="displayType === 'manager'" class="icon" href="https://my.15five.com/report/current/">

                <!--TODO: Replace with pro version icon-->
                <i class="fas fa-expand-alt"></i>
            </a>
        </div>

        <div class="card-content" v-if="teamPriorities && teamPriorities.length">
            <div v-for="priority in teamPriorities" :key="priority" class="item">
                <input type="checkbox" :id="priority.description" :checked="priority.status === 'done'" disabled />
                <label :for="priority.description">{{priority.description}}</label>
            </div>
        </div>
        <NoContent v-else text="Priorities not submitted" />
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import NoContent from "./NoContent";

    export default {
        name: 'UserPriorities',
        components: {
            NoContent
        },
        computed: {
            ...mapGetters({
                teamPriorities: 'teamPriorities',
                displayType: 'displayType',
            })
        }
    }
</script>

<style scoped>
    .card-section {
        background-color: white;
        border-radius: 15px;
        margin-top: 16px;
        text-align: left;
    }

    .card-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64656F;
        padding: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #F3F3F3;
        letter-spacing: 0.05em;
        display: flex;
        justify-content: space-between;
    }
    .card-title > span {
        padding-right: 10px;
    }

    .card-content {
        padding: 16px;
    }
    .item {
        font-size: 12px;
        line-height: 13px;
        background: #F3F3F3;
        border-radius: 4px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        margin-bottom: 4px;
        column-gap: 5px;
        position: relative;
    }
    
    .item label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0 9px 36px;
        cursor: not-allowed;
    }

    .item:hover label {
        white-space: initial;
    }

    input {
        appearance: none;
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0 0 0 9px;
        cursor: not-allowed;
    }

    input:focus {
        outline: none;
    }
    input:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 15px;
        left: 0;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: white;
    }
    input:before {
        content: '';
        position: absolute;
        border: 1px solid rgba(100, 101, 111, 0.5);
        border-top-width: 0;
        border-left-width: 0;
        z-index: 9;
        width: 3px;
        height: 7px;
        top: 10px;
        left: 6px;
        transform: rotate(45deg) scaleY(1);
    }
    input:checked:before {
        border-color: white;
    }
    input:checked:after {
        background-color: #8CBF7F;
    }

    .icon {
        position: relative;
        margin-right: 9px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #64656f;
    }
    .icon::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: #f3f3f3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: .3s;
    }
    .icon:hover::after {
        background-color: #eaeaea;
    }
    .icon i {
        position: relative;
        z-index: 9;
        font-size: 8px;
    }
</style>
