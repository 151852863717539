<template>
    <el-input
        :placeholder="searchPlaceholder"
        v-model="searchText"
        autofocus
        @keyup.enter="searchAction">
        <template #prepend>
            <el-switch
                v-model="searchInternal"
                active-color="#f3f3f3"
                inactive-color="#f3f3f3"
                active-text="c"
                inactive-text="r"
                :width="100">
            </el-switch>

            <!--TODO: Replace with pro version icon-->
            <i class="fas fa-search"></i>
        </template>
    </el-input>
</template>

<script>
export default {
    name: 'SearchBar',
    data(){
        return {
            searchText: '',
            searchInternal: false
        }
    },
    computed: {
        searchPlaceholder: function() {
            if(!this.searchInternal) {
                return "Search in Google or visit URL";
            }

            return "Search internal knowledge base"
        }
    },
    methods: {
        searchAction: function() {
            if(this.searchText.trim().length === 0) {
                return false;
            }

            if(this.validURL(this.searchText)) {
                return window.location.href = this.getUrl(this.searchText);
            }

            if(!this.searchInternal) {
                var googleHref = "https://www.google.com/search?q=" + this.searchText;
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: googleHref,
                  }).click();
                return;
            }

            return window.location.href = "https://app.getguru.com/search?q=" + this.searchText;
        },
        validURL: function(str) {
            let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

            return !!pattern.test(str);
        },
        getUrl: function(url) {
            let http = 'http://', https = "https://";
            if (url.substr(0, http.length) !== http && url.substr(0, https.length) !== https) {
                url = https + url;
            }
            return url;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-input {
    width: auto;
    margin: 0 16px 0 0;
    flex-grow: 1;
    padding: 0;
}

.el-input i {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.el-input >>> .el-input__inner {
    border: none;
    padding-left: 5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 36px;
}

.el-input >>> .el-input-group__prepend {
    padding: 5px;
    background-color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    position: initial;
}

.el-switch >>> .el-switch__action {
    background-color: #64656f;
    height: 100%;
    width: 50px;
    border-radius: 15px;
}

.el-switch.is-checked >>> .el-switch__core .el-switch__action {
    margin-left: -50px;
}

.el-switch >>> .el-switch__core {
    height: 30px;
    border-radius: 15px
}

.el-switch >>> .el-switch__label {
    position: absolute;
    height: 22px;
    width: 22px;
    top: 0;
    z-index: 1;
    color: transparent;
    background-position: center;
    border-radius: 50%;
    background-repeat: no-repeat;
}

.el-switch >>> .el-switch__label.el-switch__label--right {
    right: 15px;
    background-image: url("../assets/guru-icon.png");
    background-size: 31px;
}

.el-switch >>> .el-switch__label.el-switch__label--left {
    left: 15px;
    background-color: white;
    background-image: url("../assets/google-icon.svg");
    background-size: 16px;
}
</style>
