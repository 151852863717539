<template>
    <div class="no-content">
        <div class="circle">
            <div class="content">
                <!--TODO: Replace with pro version icon-->
                <i class="fas fa-ghost"></i>
                <div>{{text}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NoContent",
    props: {
        text: String
    }
}
</script>

<style scoped>
    .no-content {
        padding: 16px;
        min-height: 168px;
    }
    .no-content .circle {
        width: 168px;
        height: 168px;
        background-color: #F9F9F9;
        border-radius: 50%;
        margin: 0 auto;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
        text-align: center;
        padding: 0 8px;
        color: #64656F;
    }
    .circle i {
        margin-bottom: 8px;
        font-size: 21px;
    }
</style>